@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Karma';
  src: url('../webfonts/karma/regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sofiapro-light';
  src: url('../webfonts/sofiapro/sofiapro-light-webfont.woff2') format('woff2'),
    url('../webfonts/sofiapro/sofiapro-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'sofiapro-light', sans-serif, Arial, Verdana;
  color: #303030;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-image: linear-gradient(to right, #f2f4f5, #c1d1e1);
}

.text-shadow {
  text-shadow: 1px 1px 2px #000;
}

.no-text-shadow-hover:hover {
  text-shadow: none;
}
